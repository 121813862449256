import { HttpRequest, HttpEvent, HttpErrorResponse, HttpHandlerFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { AuthService } from '@core/services/api/auth.service';

export const responseInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const _messageService = inject(MessageService);
  const _authService = inject(AuthService);

  if (req.url.includes('login') || req.url.includes('configuracion/usuarios')) {
    return next(req).pipe(
      tap((event: HttpEvent<unknown>) => {
        return true;
      }),
      catchError((httpErrorResponse: HttpErrorResponse) => {
        const error = httpErrorResponse.error;
        _messageService.add({
          key: 'toast',
          detail: error.message,
          severity: 'warn',
          summary: 'Error',
        });

        if (httpErrorResponse.status === 401) {
          _authService.logout();
        }

        return throwError(() => httpErrorResponse);
      }),
    );
  }
  return next(req);
};
