import {
  HttpErrorResponse,
  HttpEvent,
  HttpInterceptorFn,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';
import { AuthService } from '@core/services/api/auth.service';

export const loginInterceptor: HttpInterceptorFn = (
  req,
  next,
): Observable<HttpEvent<unknown>> => {
  const _MESSAGESERVICE = inject(MessageService);
  const _AUTHSERVICE = inject(AuthService);

  if (req.url.includes('login')) {
    return next(req).pipe(
      tap((event: HttpEvent<unknown>) => {
        return true;
      }),
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.status === 401) {
          _AUTHSERVICE.logout();
        }
        _MESSAGESERVICE.add({
          key: httpErrorResponse.error.message === '' ? '' : 'toast',
          detail:
            httpErrorResponse.error.message !== ''
              ? httpErrorResponse.status === 401
                ? httpErrorResponse.error.message
                : 'Error desconocido'
              : 'Error desconocido',
          severity: 'warn',
          summary: 'Error',
        });

        return throwError(() => httpErrorResponse);
      }),
    );
  }

  return next(req);
};
